'use strict';

var isMobile = !($(window).width() >= 768);

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked to add a product to the wishlist
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        $('.js-wishlist-product-added').removeClass('d-none');
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append(
            '<div class="add-to-wishlist-messages "></div>'
        );
    }
    $('.add-to-wishlist-messages')
        .append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
        button.removeAttr('disabled');
    }, 5000);
}

/**
 * renders the list up to a given page number
 * @param {number} pageNumber - current page number
 * @param {boolean} spinner - if the spinner has already started
 * @param {string} focusElementSelector - selector of the element to focus on
 */
function renderNewPageOfItems(pageNumber, spinner, focusElementSelector) {
    var publicView = $('.wishlistItemCardsData').data('public-view');
    var listUUID = $('.wishlistItemCardsData').data('uuid');
    var url = $('.wishlistItemCardsData').data('href');
    if (spinner) {
        $.spinner().start();
    }
    var scrollPosition = document.documentElement.scrollTop;
    var newPageNumber = pageNumber;
    $.ajax({
        url: url,
        method: 'get',
        data: {
            pageNumber: ++newPageNumber,
            publicView: publicView,
            id: listUUID
        }
    }).done(function (data) {
        $('.wishlistItemCards').empty();
        $('body .wishlistItemCards').append(data);

        if (focusElementSelector) {
            $(focusElementSelector).focus();
        } else {
            document.documentElement.scrollTop = scrollPosition;
        }
    }).fail(function () {
        $('.more-wl-items').remove();
    });
    $.spinner().stop();
}

/**
 * @param {Object} $elementAppendTo - The element to append error html to
 * @param {string} msg - The error message
 * display error message if remove item from wishlist failed
 */
function displayErrorMessage($elementAppendTo, msg) {
    if ($('.remove-from-wishlist-messages').length === 0) {
        $elementAppendTo.append(
            '<div class="remove-from-wishlist-messages "></div>'
        );
    }
    $('.remove-from-wishlist-messages')
        .append('<div class="remove-from-wishlist-alert text-center alert-danger">' + msg + '</div>');

    setTimeout(function () {
        $('.remove-from-wishlist-messages').remove();
    }, 3000);
}

module.exports = {

    addRemoveToWishlist: function () {
        $(document)
            .off('click', '.js-add-to-wish-list')
            .on('click', '.js-add-to-wish-list', function (e) {
                e.preventDefault();
                var $container = $(this);
                var url = $(this).data('href');
                var urlRemove = $(this).data('url');
                var button = $(this);
                var pid = $(this).closest('.product-detail').data('pid');
                var lineItemPid = $(this).data('id');
                var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
                var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
                optionId = optionId || null;
                optionVal = optionVal || null;
                pid = pid || lineItemPid;

                if (!url || !pid) {
                    return;
                }

                // $(this).attr('disabled', true);

                var page = 'home';
                if ($('.page').attr('data-action') === 'Home-Show') {
                    page = 'Home';
                } else if ($('.page').attr('data-action') === 'Product-Show') {
                    page = 'L2';
                } else if ($('.page').attr('data-action') === 'Cart-Show') {
                    page = 'Cart';
                } else if ($('.page').attr('data-action') === 'Wishlist-Show') {
                    page = 'Wishlist';
                }

                if ($container.hasClass('added')) {
                    $.ajax({
                        url: urlRemove,
                        type: 'get',
                        data: {
                            pid: pid,
                            optionId: optionId,
                            optionVal: optionVal
                        },
                        success: function (response) {
                            if (!isMobile) {
                                $('.product-detail').each(function () {
                                    var thisPid = $(this).data('pid');
                                    if (thisPid === pid) {
                                        $(this).find('.js-add-to-wish-list').removeClass('added');

                                        if (page === 'Wishlist') {
                                            $('.modalBox .close').trigger('click');
                                            $(this).remove();

                                            if ($('.wishlist-item').length === 0) {
                                                $('.empty-state').removeClass('hidden');
                                                $('.content-wrapper .profile-container > .mainTitle').remove();
                                            // $('.footerIcon.wishlist').removeClass('footerIcon');
                                            }
                                        }
                                    }
                                });
                            } else {
                                $container.removeClass('added');
                            }
                            if (response.listIsEmpty) {
                                $('.mobile-footer-icon').find('.wishlist').addClass('empty');
                            }
                            $(document).trigger('wishlist:removed');
                        },
                        error: function (err) {
                            displayMessage(err, button);
                        }
                    });
                } else {
                    $.ajax({
                        url: url,
                        type: 'post',
                        dataType: 'json',
                        data: {
                            pid: pid,
                            optionId: optionId,
                            optionVal: optionVal
                        },
                        success: function (data) {
                            $('.mobile-footer-icon').find('.wishlist').removeClass('empty');
                            displayMessage(data, button);
                            if (!isMobile) {
                                $('.product-detail').each(function () {
                                    var thisPid = $(this).data('pid');

                                    if (thisPid === pid) {
                                        $(this).find('.js-add-to-wish-list').addClass('added');
                                    }
                                });
                            } else {
                                $container.addClass('added');
                            }
                            $(document).trigger('wishlist:added');
                        },
                        error: function (err) {
                            displayMessage(err, button);
                        }
                    });
                }
            });
    },

    removeFromWishlist: function () {
        $('body').on('click', '.remove-from-wishlist', function (e) {
            e.preventDefault();
            var url = $(this).data('url');
            var elMyAccount = $('.account-wishlist-item').length;
            var $parent = $(this).closest('.wishlist-item');

            // If user is in my account page, call removeWishlistAccount() end point, re-render wishlist cards
            if (elMyAccount > 0) {
                $('.wishlist-account-card').spinner().start();
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'html',
                    data: {},
                    success: function (html) {
                        if (isMobile) {
                            $parent.remove();
                            if ($('.wishlist-item').length === 0) {
                                $('.empty-state').removeClass('d-none');
                                $('.content-wrapper .container > header').remove();
                                // $('.footerIcon.wishlist').removeClass('footerIcon');
                            }
                        } else {
                            $('.wishlist-account-card>.card').remove();
                            $('.wishlist-account-card').append(html);
                            $('.wishlist-account-card').spinner().stop();
                        }
                    },
                    error: function () {
                        var $elToAppend = $('.wishlist-account-card');
                        $elToAppend.spinner().stop();
                        var msg = $elToAppend.data('error-msg');
                        displayErrorMessage($elToAppend, msg);
                    }
                });
            // else user is in wishlist landing page, call removeProduct() end point, then remove this card
            } else {
                $.spinner().start();
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    data: {},
                    success: function (response) {
                        $parent.remove();
                        if ($('.wishlist-item').length === 0) {
                            $('.empty-state').removeClass('hidden');
                            $('.content-wrapper .profile-container > .mainTitle').remove();
                            // $('.footerIcon.wishlist').removeClass('footerIcon');
                        }
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                        var $elToAppendWL = $('.wishlistItemCards');
                        var msg = $elToAppendWL.data('error-msg');
                        displayErrorMessage($elToAppendWL, msg);
                    }
                });
            }
        });
    }
};
