/* eslint-disable require-jsdoc */
module.exports = {
    init: function () {
        var inputs = $('.js-fake-input');

        function getSelected(inp, sel) {
            inp.val(sel.find(':selected').text());
        }

        inputs.each(function () {
            var $this = $(this);
            var $parent = $this.parent();
            var $sel = $parent.find('.js-select');

            getSelected($this, $sel);
            // eslint-disable-next-line no-loop-func
            $sel.on('change', function () {
                getSelected($this, $sel);
            });
        });
    },
    onAjax: function () {
        $(document).on('live:initDial', this.init);
    }
};
