/* eslint-disable require-jsdoc */

var toastr = require('./toastr');

module.exports = {
    handleNotifications: function () {
        $('.js-handle-notification-pref input[type="checkbox"]').on('change', function () {
            var action = $('.js-handle-notification-pref').data('action');
            $.ajax({
                url: action,
                type: 'POST',
                data: { enabled: $(this).is(':checked'), prefType: $(this).val() },
                success: function (data) {
                    if (!data.success) {
                        window.location.reload();
                    }
                }
            });
        });
    },

    handleOfferAddToCart: function () {
        $('.js-add-offer-to-cart').on('click', function (e) {
            e.preventDefault();
            var $isUserLoggedIn = $('#isUserLoggedIn').val();
            if ($isUserLoggedIn === "true") {
                var $that = $(this);
                $.spinner().start();
                var bundleIds = $(this).data('bundle-ids');
                var promoCode = $(this).data('coupon-id');
                var actionUrl = $(this).data('add-offer-link');
                var actionUrl = $(this).data('add-offer-link');
                if (actionUrl !== '' && bundleIds !== '') {
                    $.ajax({
                        url: actionUrl,
                        type: 'POST',
                        data: { bundleIds: JSON.stringify(bundleIds.split(',')), promoCode: promoCode },
                        success: function (data) {
                            if (data.error) {
                                $.spinner().stop();
                                toastr( {error: true}, data.message);
                            } else {
                                window.location.href = $that.attr('href');
                            }
                        }
                    })
                }
            }
        });
    }
};
