module.exports = {
    // Create or update session storage
    create: function (key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    },

    // Get value from session storage
    get: function (key) {
        var value = sessionStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    },

    // Delete item from session storage
    delete: function (key) {
        sessionStorage.removeItem(key);
    },

    // Clear all session storage
    clearAll: function () {
        sessionStorage.clear();
    }
};
