/* eslint-disable no-unused-vars */
'use strict';

import Swiper from 'swiper/bundle';


// eslint-disable-next-line new-cap
var mySwiper = new Swiper('.main-site-banner .swiper-container', {
    // Navigation arrows
    loop: true,
    speed: 620,
    autoplay: false,
    pagination: {
        el: '.main-site-banner .swiper-pagination',
    },
    navigation: {
        nextEl: '.main-site-banner  .swiper-button-next',
        prevEl: '.main-site-banner  .swiper-button-prev',
    },
});

var mySwiper = new Swiper('.main-product-banner .swiper-container', {
    // Navigation arrows
    pagination: {
        el: '.main-product-banner .swiper-pagination',
    },
    navigation: {
        nextEl: '.main-product-banner  .swiper-button-next',
        prevEl: '.main-product-banner  .swiper-button-prev',
    },
});

$(document).on('shipping:enabled', function () {
    var mySwipers = new Swiper('.js-deliver-option .swiper-container', {
        direction: 'horizontal',
        freeMode: true,
        spaceBetween: 11,
        mousewheel: false,
        slidesPerView: 2,
    });
});

$('body').on('live:initDial', function (e) {
    $('.bottom-ticker').each(function (index) {
        var $this = $(this);
        $this.addClass('ticker' + index);

        var bottomTicker = new Swiper('.ticker' + index, {
            direction: "vertical",
            allowTouchMove: true,
            loop: false,
            watchOverflow: true,
            touchEventsTarget: 'wrapper',
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
            },
            spaceBetween: 12,
            pagination: {
                el: '.ticker' + index + ' .swiper-pagination',
                type: 'bullets',
                clickable: false,
            },
        });
    });
});

$('body').on('live:soldoutLoaded', function() {
    const soldoutSlider = new Swiper('.soldout-swiper-init', {
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: 16,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        preloadImages: false,
        watchSlidesVisibility: true,
        freeMode: true,
        pagination: {
            dynamicBullets: true,
            el: ".soldout-swiper-init .swiper-pagination",
            clickable: true,
          },
        navigation: {
            nextEl: '.soldout-swiper-init .swiper-button-next',
            prevEl: '.soldout-swiper-init .swiper-button-prev',
        },
        // Enable lazy loading
    });
})


$('.bottom-ticker').each(function (index) {
    var $this = $(this);
    $this.addClass('ticker' + index);

    var bottomTicker = new Swiper('.ticker' + index, {
        direction: "vertical",
        allowTouchMove: true,
        loop: false,
        watchOverflow: true,
        touchEventsTarget: 'wrapper',
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
        },
        spaceBetween: 12,
        pagination: {
            el: '.ticker' + index + ' .swiper-pagination',
            type: 'bullets',
            clickable: false,
        },
    });
});
