'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var isMobile = !($(window).width() >= 768);

module.exports = function () {
    $('.country-selector a.dropdown-item').on('click', function (e) {
        e.preventDefault();
        var action = $('.page').data('action');
        var localeCode = $(this).data('locale');
        var localeCurrencyCode = $(this).data('currencycode');
        var queryString = $('.page').data('querystring');
        var url = $('.country-selector').data('url');
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: {
                code: localeCode,
                queryString: queryString,
                CurrencyCode: localeCurrencyCode,
                action: action
            },
            success: function (response) {
                var gTagData = {
                    localeCode: localeCode
                };
                $(document).trigger('country:success', gTagData);
                if (response && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                } else {
                    $.spinner().stop();
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    keyboardAccessibility(
        '.country-selector',
        {
            40: function ($countryOptions) {
                // down
                if ($(this).is(':focus')) {
                    $countryOptions.first().focus();
                } else {
                    $(':focus')
                        .next()
                        .focus();
                }
            },
            38: function ($countryOptions) {
                // up
                if (
                    $countryOptions.first().is(':focus') ||
                    $(this).is(':focus')
                ) {
                    $(this).focus();
                    $(this).removeClass('show');
                } else {
                    $(':focus')
                        .prev()
                        .focus();
                }
            },
            27: function () {
                // escape
                $(this).focus();
                $(this)
                    .removeClass('show')
                    .children('.dropdown-menu')
                    .removeClass('show');
            },
            9: function () {
                // tab
                $(this)
                    .removeClass('show')
                    .children('.dropdown-menu')
                    .removeClass('show');
            }
        },
        function () {
            if (!$(this).hasClass('show')) {
                $(this).addClass('show');
            }
            return $(this)
                .find('.dropdown-country-selector')
                .children('a');
        }
    );

    $('.country-selector').on('mouseenter', function () {
        $(this)
            .addClass('show')
            .children('.dropdown-menu')
            .addClass('show');
    });

    $('.country-selector').on('mouseleave', function () {
        $(this)
            .removeClass('show')
            .children('.dropdown-menu')
            .removeClass('show');
    });

    if (isMobile) { 
        $('#MobileLanguage .done-btn').click(function () {
            var $cur = $('#MobileLanguage');
            var $curText = $cur.attr('data-selected');
            var $preText = $cur.attr('data-prev-selected');

            if ($cur.hasClass('active') && ($curText !== $preText)) {
                var action = $('.page').data('action');
                var localeCode = $cur.find('.active').data('locale');
                var localeCurrencyCode = $cur.find('.active').data('currencycode');
                var queryString = $('.page').data('querystring');
                var url = $('.country-selector').data('url');

                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    data: {
                        code: localeCode,
                        queryString: queryString,
                        CurrencyCode: localeCurrencyCode,
                        action: action
                    },
                    success: function (response) {
                        $.spinner().stop();
                        var gTagData = {
                            localeCode: localeCode
                        };
                        $(document).trigger('country:success', gTagData);
                        if (response && response.redirectUrl) {
                            window.location.href = response.redirectUrl;
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
            $cur.removeClass('active');
            $('html, body').removeClass('removeScroll');
        });
    } else {
        $('#languageSelector').on('change', function() {
            var $cur = $('#languageSelector');
            var localeCodeText = $( "#languageSelector option:selected" ).text();
            $cur.attr('data-selected', localeCodeText);
            var $curText = $cur.attr('data-selected');
            var $preText = $cur.attr('data-prev-selected');

            if (($curText !== $preText)) {
                var action = $('.page').data('action');
                var localeCode = $( "#languageSelector option:selected" ).val();
                var localeCurrencyCode = $( "#currencySelector option:selected" ).val();
                var queryString = $('.page').data('querystring');
                var url = $cur.data('url');

                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    data: {
                        code: localeCode,
                        queryString: queryString,
                        CurrencyCode: localeCurrencyCode,
                        action: action
                    },
                    success: function (response) {
                        $.spinner().stop();
                        var gTagData = {
                            localeCode: localeCode
                        };
                        $(document).trigger('country:success', gTagData);
                        if (response && response.redirectUrl) {
                            window.location.href = response.redirectUrl;
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
            $cur.removeClass('active');
            $('html, body').removeClass('removeScroll');
        })
    }
};
