'use strict';

module.exports = function () {
    $('.campaign-tabs li a').on('click', function (e) {
        e.preventDefault();
        var tab = $(this).data('tab');
        $(this).parent().addClass('active');
        $(this).parent().siblings().removeClass('active');
        if (tab === 'product') {
            $('.campaign-tabs-content #content1').hide();
            $('.campaign-tabs-content #content2').show();
            $('.campaign-image-block .product-image').css({ display: 'flex' });
            $('.campaign-image-block .prize-image').hide();
            $('.campaign-image-block .combine-image').hide();
        } else if (tab === 'prize') {
            $('.campaign-tabs-content #content1').show();
            $('.campaign-tabs-content #content2').hide();
            $('.campaign-image-block .product-image').hide();
            $('.campaign-image-block .prize-image').css({ display: 'flex' });
            $('.campaign-image-block .combine-image').hide();
        }
    });
};

