/* eslint-disable no-lonely-if */
/* eslint-disable no-alert */
'use strict';

var ClipboardJS = require('clipboard');
var isMobile = !($(window).width() >= 768);

module.exports = function () {
    $('#hlIC').on('click', function () {
        // eslint-disable-next-line no-unused-vars
        var clipboard = new ClipboardJS('.invitation-code');
        $('.invitation-label').text(resources.copied).css({ color: '#61c652' });

        $('.invitation-code').css({
            backgroundColor: '#61c652',
            color: '#ffffff'
        });
        $('.invitation-code').find('.icon-copy-content').hide();
        setTimeout(function () {
            $('.invitation-label').text(resources.afterCopy).css({ color: '#4a4a4a' });

            $('.invitation-code').css({
                backgroundColor: '#ffffff',
                color: '#4a4a4a'
            });
            $('.invitation-code').find('.icon-copy-content').show();
        }, 2000);
    })
};
