/* eslint-disable no-undef */
'use strict';
var SESSION_STORAGE = require('./sessionStorageUtils');

var currencySelected = $('.page').attr('data-current-currency');
var languageSelected = $('.page').attr('data-current-lang');

var MOENGAGE_EVENT_NAME = {
    registrationFailed: 'registration_failed',
    registrationSuccess: 'registration_success',
    loginFailed: 'log_in_failed',
    loginSuccess: 'log_in_success',
    logout: 'log_out',
    changeLanguage: 'change_language',
    changeCurrency: 'change_currency',
    updateProfile: 'update_profile',
    pageView: 'page_view',
    addToCart: 'add_to_cart',
    removeFromCart: 'remove_from_cart',
    addToWishlist: 'add_to_wishlist',
    removeFromWishlist: 'remove_from_wishlist',
    addQuantity: 'add_quantity',
    removeQuantity: 'remove_quantity',
    promoCodeAdded: 'promo_code_success',
    promoCodeRemoved: 'promo_code_removed',
    promoCodeFailed: 'promo_code_failed',
    weeklyCampaignEnable: 'weekly_campaign_enable',
    weeklyCampaignDisable: 'weekly_campaign_disable',
    weeklyCampaignPopupYes: 'weekly_campaign_popup_yes',
    weeklyCampaignPopupNo: 'weekly_campaign_popup_no',
    initiateCheckout: 'initiate_checkout',
    selectPayment: 'select_payment',
    selectCredit: 'select_credit',
    purchaseSuccess: 'purchase_success',
    purchaseDetail: 'purchase_detail',
    purchaseFailed: 'purchase_failed',
    orderSuccessNewCustomer: 'order_success - new customers',
    walletTopup: 'topup_success',
    purchase: 'purchase'
};
// eslint-disable-next-line no-nested-ternary
var userID =
    typeof GTM_CUSTOMER !== 'undefined' && GTM_CUSTOMER && GTM_CUSTOMER.customerNo
        ? GTM_CUSTOMER.customerNo
        : 'Not Login'; // eslint-disable-line no-undef

var MOENGAGE_ATTR = {
    app_name: 'Idealz Lebanon',
    origin: 'Idealz Lebanon',
    platform: 'Web'
};

window.addEventListener('MOE_AUTOMATED_EVENTS', function (event) {
    switch (event.detail.name) {
        case 'MOE_PAGE_VIEWED':
            Moengage.track_event(
                MOENGAGE_EVENT_NAME.pageView,
                Object.assign({}, MOENGAGE_ATTR, {
                    screen_name: document.title + ' ' + 'screen'
                })
            );
            break;
        default:
            break;
    }
});
var removePlusSign = function (phoneText) {
    return phoneText ? phoneText.replace('+', '') : phoneText;
};

var trackUserAttributes = function (user) {
    if (user) {
        Moengage.add_first_name(user.firstName);
        Moengage.add_last_name(user.lastName);
        Moengage.add_email(user.email);
        Moengage.add_mobile(user.mobileNumber);
        // Moengage.add_user_name(user.firstName + ' ' + user.lastName); // Full name for user
        // Moengage.add_gender(user.gender);
        // Moengage.add_birthday(user.dateOfBirth);
        Moengage.add_user_attribute('nationality', user.nationality);
        // Moengage.add_user_attribute('currency', currencySelected);
        // Moengage.add_user_attribute('language', languageSelected);
        Moengage.add_user_attribute('origin', user.origin || MOENGAGE_ATTR.origin);
        Moengage.add_user_attribute('platform', MOENGAGE_ATTR.platform);
    }
};

var trackUserEvents = function (eventName, param, redirectURL) {
    if (typeof Moengage !== 'undefined' && Moengage) {
        switch (eventName) {
            case MOENGAGE_EVENT_NAME.addToCart:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.addToCart,
                    Object.assign({}, MOENGAGE_ATTR, param)
                ).finally(function () {
                    if (redirectURL) {
                        location.href = redirectURL;
                    }
                });
                break;
            case MOENGAGE_EVENT_NAME.removeFromCart:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.removeFromCart,
                    Object.assign({}, MOENGAGE_ATTR, param)
                ).finally(function () {
                    if (redirectURL) {
                        location.href = redirectURL;
                    }
                });
                break;
            case MOENGAGE_EVENT_NAME.addQuantity:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.addQuantity,
                    Object.assign({}, MOENGAGE_ATTR, param.items[0], param)
                ).finally(function () {
                    if (redirectURL) {
                        location.href = redirectURL;
                    }
                });
                break;
            case MOENGAGE_EVENT_NAME.removeQuantity:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.removeQuantity,
                    Object.assign({}, MOENGAGE_ATTR, param.items[0], param)
                ).finally(function () {
                    if (redirectURL) {
                        location.href = redirectURL;
                    }
                });
                break;
            case MOENGAGE_EVENT_NAME.addToWishlist:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.addToWishlist,
                    Object.assign({}, MOENGAGE_ATTR, param.items[0], param)
                );
                break;
            case MOENGAGE_EVENT_NAME.removeFromWishlist:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.removeFromWishlist,
                    Object.assign({}, MOENGAGE_ATTR, param.items[0], param)
                );
                break;
            case MOENGAGE_EVENT_NAME.loginFailed:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.loginFailed,
                    Object.assign({}, MOENGAGE_ATTR, param)
                );
                break;
            case MOENGAGE_EVENT_NAME.loginSuccess:
                Promise.all([
                    Moengage.add_unique_user_id(param.userID),
                    Moengage.track_event(
                        MOENGAGE_EVENT_NAME.loginSuccess,
                        MOENGAGE_ATTR
                    )
                ]).then(function () {
                    trackUserAttributes(param);
                }).finally(function () {
                    if (redirectURL) {
                        location.href = redirectURL;
                    }
                });
                break;
            case MOENGAGE_EVENT_NAME.logout:
                Moengage.destroy_session().then(function () {
                    location.href = redirectURL;
                });
                break;
            case MOENGAGE_EVENT_NAME.registrationFailed:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.registrationFailed,
                    Object.assign({}, MOENGAGE_ATTR, param)
                );
                break;
            case MOENGAGE_EVENT_NAME.registrationSuccess:
                Promise.all([
                    Moengage.add_unique_user_id(param.userID),
                    Moengage.track_event(
                        MOENGAGE_EVENT_NAME.registrationSuccess,
                        MOENGAGE_ATTR
                    )
                ]).then(function () {
                    trackUserAttributes(param);
                });
                break;
            case MOENGAGE_EVENT_NAME.promoCodeAdded:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.promoCodeAdded,
                    Object.assign({}, MOENGAGE_ATTR, param)
                ).finally(function () {
                    if (redirectURL) {
                        location.href = redirectURL;
                    }
                });
                break;
            case MOENGAGE_EVENT_NAME.promoCodeRemoved:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.promoCodeRemoved,
                    Object.assign({}, MOENGAGE_ATTR, param)
                ).finally(function () {
                    if (redirectURL) {
                        location.href = redirectURL;
                    }
                });
                break;
            case MOENGAGE_EVENT_NAME.promoCodeFailed:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.promoCodeFailed,
                    Object.assign({}, MOENGAGE_ATTR, param)
                );
                break;
            case MOENGAGE_EVENT_NAME.walletTopup:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.walletTopup,
                    Object.assign({}, MOENGAGE_ATTR, param)
                ).finally(function () {
                    if (redirectURL) {
                        location.href = redirectURL;
                    }
                });
                break;

            case MOENGAGE_EVENT_NAME.updateProfile:
                trackUserAttributes(param);
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.updateProfile,
                    MOENGAGE_ATTR
                ).finally(function () {
                    if (redirectURL) {
                        location.href = redirectURL;
                    }
                });
                break;
            case MOENGAGE_EVENT_NAME.initiateCheckout:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.initiateCheckout,
                    Object.assign({}, MOENGAGE_ATTR, param)
                ).finally(function () {
                    if (redirectURL) {
                        location.href = redirectURL;
                    }
                });
                break;
            case MOENGAGE_EVENT_NAME.selectCredit:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.selectCredit,
                    Object.assign({}, MOENGAGE_ATTR, param)
                ).finally(function () {
                    if (redirectURL) {
                        location.href = redirectURL;
                    }
                });
                break;

            case MOENGAGE_EVENT_NAME.purchaseFailed:
                Moengage.track_event(MOENGAGE_EVENT_NAME.purchaseFailed, Object.assign({}, MOENGAGE_ATTR, param));
                break;

            case MOENGAGE_EVENT_NAME.changeLanguage:
                Moengage.add_user_attribute('language', param.localeCode);
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.changeLanguage,
                    Object.assign({}, MOENGAGE_ATTR, param)
                );
                break;
            case MOENGAGE_EVENT_NAME.changeCurrency:
                Moengage.add_user_attribute('currency', param.localeCurrencyCode);
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.changeCurrency,
                    Object.assign({}, MOENGAGE_ATTR, param)
                );
                break;

            case MOENGAGE_EVENT_NAME.purchase:
                if (param && param.items && param.items.length > 0) {
                    Moengage.track_event(
                        MOENGAGE_EVENT_NAME.purchaseSuccess,
                        Object.assign({}, MOENGAGE_ATTR, param, {
                            total_items: param.items.length
                        })
                    );
                    param.items.forEach(function (item) {
                        Moengage.track_event(
                            MOENGAGE_EVENT_NAME.purchaseDetail,
                            Object.assign({}, MOENGAGE_ATTR, item, {
                                transaction_id: param.transaction_id
                            })
                        );
                    });
                }
                break;
            case MOENGAGE_EVENT_NAME.addBundleOffer:
                Moengage.track_event(
                    MOENGAGE_EVENT_NAME.addBundleOffer,
                    Object.assign({}, MOENGAGE_ATTR, param)
                );
                break;
            default:
                break;
        }
    }
};

var gtagElems = $('span[data-gtag-event]:not(.begin_checkout)');
window.addEventListener('MOE_LIFECYCLE', function (e) {
    if (e.detail.name === 'SDK_INITIALIZED') {
        if (gtagElems.length > 0) {
            gtagElems.each(function () {
                
                var gtagElem = $(this);
                var eventName = gtagElem.data('gtag-event');
                var param = gtagElem.attr('data-gtag-param');
                if (eventName && eventName.toLowerCase() === 'purchase' && param) {
                    trackUserEvents(
                        MOENGAGE_EVENT_NAME.purchase,
                        JSON.parse(param)
                    );
                    // console.log(JSON.parse(param));
                }

            //     /*
            //     Set User attributes values after switching to other site
            // */
            //     if (COOKIE.getCookie('moss')) {
            //         if (eventName && eventName.toLowerCase() === 'user_data_gtag' && param) {
            //             param = JSON.parse(param);
            //             Moengage.add_unique_user_id(param.userID)
            //                 .then(function () {
            //                     trackUserAttributes(param);
            //                 });
            //         }
            //         COOKIE.deleteCookie('moss');
            //     }
            });

            if ($('.mobileSpecialPaymentError.errorFound').length > 0) {
                trackUserEvents(
                    MOENGAGE_EVENT_NAME.purchaseFailed, { error: $(this).find('span').text() }
                );
            }
        }

        if (typeof GTM_CUSTOMER !== 'undefined' && GTM_CUSTOMER && userID !== 'Not Login' && !SESSION_STORAGE.get('user_logged_in')) {
            trackUserEvents(MOENGAGE_EVENT_NAME.loginSuccess, {
                userID: GTM_CUSTOMER.customerNo ? GTM_CUSTOMER.customerNo : '',
                firstName: GTM_CUSTOMER.firstName ? GTM_CUSTOMER.firstName : '',
                lastName: GTM_CUSTOMER.lastName ? GTM_CUSTOMER.lastName : '',
                email: GTM_CUSTOMER.customerEmail ? GTM_CUSTOMER.customerEmail : '',
                mobileNumber: GTM_CUSTOMER.phone ? GTM_CUSTOMER.phone : '',
                nationality: GTM_CUSTOMER.nationality ? GTM_CUSTOMER.nationality : ''
            });
            SESSION_STORAGE.create('user_logged_in', true);
        } else if (userID === 'Not Login' && SESSION_STORAGE.get('user_logged_in')) {
            SESSION_STORAGE.delete('user_logged_in');
        }
        // else if (userID === 'Not Login' && COOKIE.getCookie('moss')) {
        //     Moengage.destroy_session();
        //     COOKIE.deleteCookie('moss');
        // }
    }
});

module.exports = {
    trackUserAttributes: trackUserAttributes,
    trackUserEvents: trackUserEvents,
    MOENGAGE_EVENT_NAME: MOENGAGE_EVENT_NAME
};
