'use strict';

module.exports = function () {
    $('.delete-confirmation-btn').click(function () {
        if ($('.saved-card-box.payment-stats').length === 1) {
            setTimeout(function () {
                $('.content-wrapper header').hide();
                $('.empty-state.hidden').removeClass('hidden');
            }, 500);
        }
    });
};
