'use strict';

import Swiper from 'swiper/bundle';
var processInclude = require('base/util');
var isMobile = !($(window).width() >= 768);

$(document).ready(function () {
    processInclude(require('./product/detail'));
    processInclude(require('./product/wishlist'));
    // processInclude(require('./components/radioButtons'));
    processInclude(require('./product/mobileDetailsTab'));

    processInclude(require('./mainMobile'));
    
    $('.miniSlider').each(function (index) {
        var $this = $(this);
        var $newClass = `newS${index}`;
        var $newClassP = `newS${index}-promotional`;
        var $parent = $this.parent();
        var launched = $parent.find('.js-promotional-message');
        launched.addClass($newClassP);
        $this.addClass($newClass);

        const newSlide = new Swiper(`.${$newClass}`, {
            preloadImages: false,
            watchSlidesVisibility: true,
            pagination: {
                el: `.${$newClass} .swiper-pagination`,
            },
        });

        const newSlides = new Swiper(`.${$newClassP}`, {
            preloadImages: false,
            watchSlidesVisibility: true,
            grabCursor: true,
            effect: "fade",
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
        });
    })

    
    const newSlides = new Swiper(`.js-associate-products-slider`, {
        preloadImages: false,
        slidesPerView: 'auto',
        watchSlidesVisibility: true,
        spaceBetween: 17,
        grabCursor: true,
        dynamicBullets: true,
        fadeEffect: {
            crossFade: true
        },
        watchOverflow: true,
        navigation: {
            nextEl: `.js-associate-products-slider .swiper-button-next`,
            prevEl: `.js-associate-products-slider .swiper-button-prev`,
        },

        pagination: {
            el: `.js-associate-products-slider .swiper-pagination`,
            dynamicBullets: true,
            dynamicMainBullets: 2
        }
    });


        const scrollPrize = new Swiper('.scrollable-prize .swiper-container', {
            slidesPerView: 'auto',
            direction: 'horizontal',
            slidesPerView: 'auto',
            spaceBetween: 6,
            mousewheel: true
        });


    $(document).on('live:reloadAssociatedPopup', function (e, $data) {
        var $newClassPPPP = 'product-bundle-added-item-popover-slide-new';
        $data.$data.find('.js-product-bundle-added-item-popover .product-bundle-item-popover-slide').addClass($newClassPPPP);
        const swiperSlides = new Swiper(`.${$newClassPPPP}`, {
            direction: "horizontal",
            slidesPerView: "auto",
            spaceBetween: 17,
            slidesOffsetBefore: 17,
            slidesOffsetAfter: 17,
            freeMode: true,
            mousewheel: true,
        });
    });

    // initaiting a slider on a campaign that was edited by user
    $(document).on('live:reloadAssociatedPopupAll', function (e, $data) {
        var $newClassPPPP = 'product-bundle-all-item-popover-slide-new';
        $data.$data.find('.js-product-bundle-item-popover .product-bundle-item-popover-slide').addClass($newClassPPPP);
        const swiperSlides = new Swiper(`.${$newClassPPPP}`, {
            direction: "horizontal",
            slidesPerView: "auto",
            spaceBetween: 17,
            slidesOffsetBefore: 17,
            slidesOffsetAfter: 17,
            freeMode: true,
            mousewheel: true,
        });
    });

    $('body').on('click', '.product-bundle-all-item-popover-slide-new .size-selected-box, .campaign-box-copy-section .size-selected-box, .asscoicateProductName .size-selected-box', function (e) {
        e.preventDefault();
        $(this).siblings('.size-selector-product.dropDown').addClass('show');
    });

    $('#copytoclip').on('click', function() {
        // Retrieve the data-value from the clicked element
        var copiedValue = $(this).data('clipboard-text');
        copiedValue = decodeURIComponent(copiedValue);
        // You can now use copiedValue as needed
        navigator.clipboard.writeText(copiedValue).then(function() {
            console.log('Value copied to clipboard:', copiedValue);
            // alert('Value copied to clipboard!');
          }, function(err) {
            console.error('Could not copy text:', err);
          });
      
        // Optionally, set it to another element
        // $('#targetElement').text(copiedValue);
      });
});
